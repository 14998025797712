import { Box, Grid, Icon, materialUiXGrid, Paper, Typography } from 'amn-ui-core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { selectUser } from 'oidc/user.selectors';
import { selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import _ from 'lodash';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { checkUserRolesForAccess } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/CommunicationHistoryTab/helper';
import { Tooltip } from 'amn-ui-core/dist/components/Tooltip/Tooltip';
import { convertToPST } from 'app/helpers/dateHelper';
import { Concatenate } from 'utils/string/string';
import { formatTime } from '@AMIEWEB/Candidate/helper/StringFormatter';
import { ConversationCount } from '@AMIEWEB/Notification/Common/ConversationCount';

import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { selectFacilityDetail } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { EmailModal } from './EmailModal';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectFacilityCommunicationLog, selectGridLoading } from '../store/FacilityCommunicationHistory.selector';
import {
  FacilityCommunicationHistoryViewInfo,
  FacilityCommunicationHistoryVisibiltyModel,
} from './FacilityCommunicationHistoryViewInfo';
import {
  communicationNotesOrSubject,
  defaultNotes,
  FacilityCommunictionHistoryFlattenData,
  getConatctNames,
  getContactInfo,
  getDelieveryStatusMessage,
  identifyCommunicationIcon,
  requestFilterPayload,
  INotes,
} from '../Helpers/helper';
import { facilityCommunicationHistoryActions, IFacilityLogs } from '../store/FacilityCommunicationHistory.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { NotesModal } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/CommunicationHistoryTab/NotesModal';
import { IUserPreferenceState, userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { RootState } from 'types';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import {
  GridSortModel,
  GridColumns as XGridColumns,
  GridColumnVisibilityModel,
  GridDensity,
} from '@mui/x-data-grid-pro';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { GridColumns } from 'app/models/GlobalSearch/GlobalSearch';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles()(theme => ({
  channelIcon: {
    color: theme.palette.framework.system.navyBlue,
    paddingRight: '10px',
    fontSize: '1.25rem !important',
    overflow: 'visible',
  },
  communicationType: {
    display: 'flex',
  },
  communicationTitle: {
    paddingTop: '3px',
    paddingLeft: '6px',
  },
  conversationType: {
    color: theme.palette.framework.system.navyBlue,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  value: {
    color: theme.palette.framework.system.doveGray,
    paddingRight: '2%',
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  title: {
    color: theme.palette.framework.system.charcoal,
    fontSize: '16px',
    fontWeight: 600,
    paddingLeft: '23px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  notes: { display: 'flex', cursor: 'pointer' },
  notesEditIcon: {
    cursor: 'pointer',
    fontSize: '20px',
  },
  addNote: {
    fontWeight: 500,
    marginTop: 2,
    cursor: 'pointer',
  },
  conversationNotes: {
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '750px',
    outline: 'none',
    borderRadius: '5px',
    boxShadow: `0px 0px 2px 6px ${theme.palette.framework.system.tertiaryGrey}`,
  },
  grid: {
    padding: '0 20px 20px 20px',
  },
  subject: {
    fontSize: '18px',
    paddingBottom: '5px',
  },
  noUnderline: {
    color: theme.palette.framework.system.doveGray,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  tooltipArrow: {
    color: theme.palette.framework.main,
    '&::before': {
      border: `1px solid ${theme.palette.framework.system.doveGray}`,
    },
  },
  tooltipBackground: {
    backgroundColor: theme.palette.framework.main,
    color: theme.palette.framework.system.doveGray,
    border: `1px solid ${theme.palette.framework.system.doveGray}`,
    fontSize: '12px',
  },
}));

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE_NUMBER = 1;

interface ICommunicationHistoryTabProps {
  filterPreference: IUserPreferenceState<any>;
}

export const CommunicationHistoryTab = (props: ICommunicationHistoryTabProps) => {
  const gridTag = `FacilityCommunicationHistoryGrid`;
  const missingField = '--';
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPageSizeChangeRef = useRef(false);
  const apiRef = materialUiXGrid.useGridApiRef();
  const params = useParams<{ id: string }>();

  const userPreference = useSelector(selectUserPreference);
  const gridPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences?.find(item => item.id === gridTag);
  });
  const gridState = useSelector(selectGridState);
  const user = useSelector(selectUser);
  const facilityCommunicationLog = useSelector(selectFacilityCommunicationLog);
  const { promiseInProgress: isGettingLogs } = usePromiseTracker({ area: 'facility-logs', delay: 0 });

  const defaultSortModel: GridSortModel = [{ field: 'date', sort: 'desc' }];
  const [rows, setRows] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLogsFetch, setLogsFetch] = useState(true);
  const [currentColumns, setCurrentColumns] = useState<any[]>([...FacilityCommunicationHistoryViewInfo(t)]);
  const [data, setData] = useState({});
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [notesModal, setNotesModal] = useState<INotes>(defaultNotes);
  const [density, setDensity] = React.useState<GridDensity>('compact');
  const [defaultColumns, setDefaultColumns] = React.useState<any[]>([...FacilityCommunicationHistoryViewInfo(t)]);
  const [currentColumnVisibilityModel, setCurrentColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(FacilityCommunicationHistoryVisibiltyModel),
  );
  const [defaultColumnVisibilityModel, setDefaultColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(FacilityCommunicationHistoryVisibiltyModel),
  );
  const [currentSortModel, setCurrentSortModel] = React.useState<any[]>([{ field: 'date', sort: 'desc' }]);

  const handleClick = (row: number, channel: string) => {
    setModalOpen(true);
    const data = facilityCommunicationLog?.results[row];
    const modalData = {};

    if (channel === 'Email') {
      modalData['title'] = 'Email Notes';
      modalData['tos'] = data?.message?.tos;
      modalData['ccs'] = data?.message?.ccs;
      modalData['bccs'] = data?.message?.bccs;
      modalData['subject'] = data?.message?.subject;
      modalData['sender'] = data?.message?.sender?.email;
    } else {
      modalData['title'] = 'Notes';
    }
    if (channel === 'Voice') {
      modalData['notes'] = data?.response?.notes;
    } else {
      modalData['notes'] = data?.message?.body;
    }
    setData(modalData);
  };

  const getCommunicationType = log => {
    return (
      <div className={classes.communicationType}>
        <Box>
          <Icon className={classes.channelIcon}>{identifyCommunicationIcon(log, '1.15rem', '7px')}</Icon>
        </Box>
        <Box className={classes.communicationTitle}>
          {log.channel === 'Voice'
            ? 'Call'
            : log.channel === 'Conversation'
            ? getSmsConversationType(log)
            : log.channel}
        </Box>
      </div>
    );
  };

  const getSmsConversationType = log => {
    const hasUserAccess = checkUserRolesForAccess(log, user);
    if (hasUserAccess) {
      return (
        <span
          className={classes.conversationType}
          role="button"
          tabIndex={0}
          onClick={() => {}}
          onKeyPress={() => {}}
        >{`SMS`}</span>
      );
    } else
      return (
        <Tooltip
          classes={{
            tooltip: classes.tooltipBackground,
            arrow: classes.tooltipArrow,
          }}
          arrow
          title={t('FacilityCommunicationHistory.AccessConversation')}
        >
          <span className={classes.noUnderline}>{t('SMS')}</span>
        </Tooltip>
      );
  };

  const formatData = logs => {
    const logData = logs?.map((log, index) => {
      const coveringEmployeeNameObject = log?.associatedRecords?.find(record => record.name === 'coveringEmployeeName');
      const sharedDeskPublisherNameObject = log?.associatedRecords?.find(
        record => record.name === 'sharedDeskPublisherName',
      );
      return {
        rowID: log?.id,
        contactName: getConatctNames(log) || missingField,
        communicationType: getCommunicationType(log) || missingField,
        contactInfo: getContactInfo(log) || missingField,
        date: `${convertToPST(log?.publishedOn, 'MM/DD/YYYY')} | ${formatTime(
          convertToPST(log?.publishedOn, 'HH:mm'),
        )}`,
        from: coveringEmployeeNameObject?.value?.trim()
          ? coveringEmployeeNameObject?.value || missingField
          : sharedDeskPublisherNameObject?.value?.trim()
          ? sharedDeskPublisherNameObject?.value || missingField
          : log?.message?.sender?.name || missingField,
        userRole:
          log?.userRoles && log?.userRoles?.length > 3 ? (
            <Tooltip
              classes={{
                tooltip: classes.tooltipBackground,
                arrow: classes.tooltipArrow,
              }}
              arrow
              title={<span>{Concatenate(log?.userRoles, ', ')}</span>}
            >
              <span>{`${Concatenate(log?.userRoles.slice(0, 2), ', ')}...`}</span>
            </Tooltip>
          ) : (
            Concatenate(log?.userRoles, ', ')
          ),
        status:
          log.channel === 'Email'
            ? log?.message?.tos && getDelieveryStatusMessage(log?.message?.tos[0], missingField)
            : log?.response?.disposition?.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1') || missingField,
        type:
          log.channel === 'Conversation' ? (
            <ConversationCount
              channel={log.channel}
              outboundCount={log?.message?.outboundCount}
              inboundCount={log?.message?.inboundCount}
              undeliveredCount={log?.message?.undeliveredMessagesCount}
            />
          ) : log?.message.direction === null ? (
            missingField
          ) : (
            <ConversationCount channel={log?.channel} direction={log?.message?.direction} />
          ),
        notes: communicationNotesOrSubject(log, index, user, classes, setNotesModal, handleClick, t),
      };
    });
    return logData;
  };

  const handlePagination = React.useCallback(
    (offset: number, page: number) => {
      const prefPayload = requestFilterPayload(props?.filterPreference?.value?.filtersApplied);

      const facilityLogsPayload: IFacilityLogs = {
        ...prefPayload,
        facilityId: parseInt(params.id),
        pageSize: offset,
        pageNumber: page,
      };
      if (!isGettingLogs && params.id) {
        dispatch(facilityCommunicationHistoryActions.getFacilityLogs(facilityLogsPayload));
      }
      dispatch(gridSelectionActions.reset());
    },
    [props?.filterPreference?.value?.filtersApplied],
  );

  const saveSortModel = React.useCallback(
    sortModel => {
      const sortedColumn =
        sortModel?.length > 0
          ? {
              column: sortModel[0].field,
              direction: sortModel[0].sort,
            }
          : defaultSortModel;

      if (!_.isEqual(sortedColumn, gridState?.sortedColumn)) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            sortedColumn: sortedColumn,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    [currentColumnVisibilityModel, dispatch, gridPreferences?.value, gridState?.sortedColumn],
  );

  const savePreferences = React.useCallback(
    ({
      field,
      columns: gridColumns,
      visibilityModel,
    }: {
      field?: string;
      columns: XGridColumns;
      visibilityModel: GridColumnVisibilityModel;
    }) => {
      if (!currentColumns || currentColumns.length === 0 || !userPreference.loaded) return;
      const currentGridColumns = new GridColumns(gridColumns).columns;

      if (currentGridColumns && currentGridColumns.length > 0 && currentGridColumns[0].field === '__check__')
        currentGridColumns.shift();

      const comparableColumns = new GridColumns(
        gridPreferences?.value?.columns,
        gridPreferences?.value?.columnVisibilityModel,
      );
      const columnsEqual = comparableColumns.isEqual(currentGridColumns);
      const visibilityEqual = comparableColumns.isVisibilityModelEqual(visibilityModel);

      if (!columnsEqual || !visibilityEqual) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            columns: currentGridColumns,
            columnVisibilityModel: visibilityModel,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    [currentColumns, userPreference.loaded, defaultColumns?.length, gridPreferences?.value, dispatch],
  );

  const getColumnPreferences = (cols: any[]) => {
    try {
      const columnPreferences = gridPreferences?.value;
      if (columnPreferences && columnPreferences.columns) {
        const newColumns = cols.map(column => {
          const foundIndex = columnPreferences.columns.findIndex(item => item?.field === column?.field);
          if (foundIndex !== -1) {
            return {
              ...column,
              ...columnPreferences.columns[foundIndex],
            };
          } else {
            return column;
          }
        });
        return newColumns.sort((a, b) => {
          return (
            columnPreferences.columns.findIndex(i => i.field === a.field) -
            columnPreferences.columns.findIndex(i => i.field === b.field)
          );
        });
      }
      return cols;
    } catch (err) {
      return cols;
    }
  };

  const getVisibilityPreferences = (cols: any) => {
    const columnPreferences = gridPreferences?.value;
    if (columnPreferences && columnPreferences?.columnVisibilityModel) {
      return columnPreferences?.columnVisibilityModel;
    }
    return cols;
  };

  const getSortModelPreferences = (sortModel: any[]) => {
    const columnPreferences = gridPreferences?.value;
    if (columnPreferences && columnPreferences?.sortedColumn) {
      const preferenceSortModel = columnPreferences?.sortedColumn;
      return [{ field: preferenceSortModel?.column, sort: preferenceSortModel?.direction }];
    }
    return sortModel;
  };

  const determineGridColumns = () => {
    const currentGridColumns = getColumnPreferences(defaultColumns);
    setCurrentColumns(currentGridColumns);
  };

  const determineVisibilityModel = () => {
    const currentVisibilityModel = getVisibilityPreferences(defaultColumnVisibilityModel);
    setCurrentColumnVisibilityModel(currentVisibilityModel);
  };

  const determineDensity = () => {
    if (gridPreferences?.value?.gridDensity) {
      setDensity(gridPreferences?.value?.gridDensity);
    }
  };

  const determineSortModel = () => {
    const currentGridSortModel = getSortModelPreferences(defaultSortModel);
    setCurrentSortModel(currentGridSortModel);
    if (
      !gridState?.sortedColumn ||
      (gridState?.sortedColumn &&
        (gridState.sortedColumn.column !== currentGridSortModel[0].field ||
          gridState.sortedColumn.direction !== currentGridSortModel[0].sort))
    ) {
      dispatch(
        gridStateActions.setSortedColumn({
          column: currentGridSortModel[0].field,
          direction: currentGridSortModel[0].sort,
        }),
      );
    }
  };

  const onPageChange = React.useCallback(
    (page: number) => {
      if (isPageSizeChangeRef.current) {
        isPageSizeChangeRef.current = false;
        return;
      }
      setPageNumber(page);
      const pagination = apiRef?.current?.state?.pagination;
      if (pagination && !isPageSizeChangeRef.current) {
        handlePagination(pagination?.pageSize, page + 1);
      }
    },
    [handlePagination],
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (isGettingLogs || isLogsFetch) {
        setLogsFetch(false);
        return;
      }
      setPageSize(pageSize);
      handlePagination(pageSize, pageNumber === 0 ? pageNumber + 1 : pageNumber);
    },
    [handlePagination],
  );

  const onSortModelChanged = React.useCallback((model: GridSortModel) => {
    if (model.length > 0) {
      saveSortModel(model);
    }
    setCurrentSortModel(model);
  }, []);

  const handleRefreshColumns = () => {
    handlePagination(DEFAULT_PAGE_SIZE, 1);
    setPageNumber(0);
    setPageSize(DEFAULT_PAGE_SIZE);
  };

  React.useEffect(() => {
    if (userPreference?.loaded) {
      dispatch(gridStateActions.setGrid(gridTag));
      determineGridColumns();
      determineVisibilityModel();
      determineDensity();
      determineSortModel();
    }
  }, [userPreference?.loaded, gridPreferences]);

  const handleGridChanges = React.useCallback(
    state => {
      if (state.density.value !== density) {
        setDensity(state.density.value);
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            gridDensity: state.density.value,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    [density, gridPreferences, gridTag, dispatch],
  );

  useEffect(() => {
    if (facilityCommunicationLog) {
      const formattedData = formatData(facilityCommunicationLog?.results);
      const newFormatedData = FacilityCommunictionHistoryFlattenData(formattedData);
      setRows(newFormatedData);
    }
  }, [facilityCommunicationLog]);

  useEffect(() => {
    const prefPayload = requestFilterPayload(props?.filterPreference?.value?.filtersApplied);
    if (!isGettingLogs && facilityCommunicationLog?.results?.length === 0 && params.id) {
      const facilityLogsPayload: IFacilityLogs = {
        ...prefPayload,
        facilityId: parseInt(params.id),
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: DEFAULT_PAGE_NUMBER,
      };
      dispatch(facilityCommunicationHistoryActions.getFacilityLogs(facilityLogsPayload));
    }
  }, [props?.filterPreference?.value, params.id]);

  const GridComponent = React.useMemo(() => {
    return (
      <>
        <XGrid
          apiRef={apiRef}
          loading={isGettingLogs}
          defaultColumns={defaultColumns}
          columns={currentColumns}
          page={pageNumber}
          pageSize={pageSize}
          rows={rows || []}
          rowsPerPageOptions={[50, 100, 250, 500]}
          defaultRowsPerPage={gridState.pageSize || DEFAULT_PAGE_SIZE}
          checkboxSelection
          height={360}
          onSortModelChange={onSortModelChanged}
          rowCount={facilityCommunicationLog?.totalRecords || 0}
          defaultSortModel={defaultSortModel}
          columnVisibilityModel={currentColumnVisibilityModel}
          defaultColumnVisibilityModel={defaultColumnVisibilityModel}
          sortModel={currentSortModel}
          paginationMode="server"
          sortingMode="client"
          disableSelectionOnClick={true}
          onStateChange={handleGridChanges}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onColumnResizeStop={_columns => {
            savePreferences({
              field: undefined,
              columns: _columns,
              visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? defaultColumnVisibilityModel),
            });
          }}
          onColumnOrderChange={(params, event, details, _columns) => {
            savePreferences({
              field: params.field,
              columns: _columns,
              visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? defaultColumnVisibilityModel),
            });
          }}
          onColumnVisibilityModelChange={(model, details) => {
            dispatch(gridStateActions.setColumnVisibilityModel(model));
            setCurrentColumnVisibilityModel(_.cloneDeep(model));
            savePreferences({
              field: undefined,
              columns: _.cloneDeep(apiRef?.current?.getAllColumns()),
              visibilityModel: _.cloneDeep(model),
            });
          }}
          toolbarProps={{
            refreshButton: true,
            resetButton: true,
            refreshGrid: () => {
              handleRefreshColumns();
            },
            resetColumns: () => {
              setCurrentColumns(FacilityCommunicationHistoryViewInfo(t));
              setCurrentColumnVisibilityModel(FacilityCommunicationHistoryVisibiltyModel);
              dispatch(gridStateActions.setSortedColumn({ column: 'date', direction: 'desc' }));
              setCurrentSortModel(defaultSortModel);
              setDensity('compact');
              const preferenceData = {
                id: gridTag,
                value: {
                  ...(gridPreferences?.value || {}),
                  columns: defaultColumns,
                  columnVisibilityModel: defaultColumnVisibilityModel,
                  sortedColumn: defaultSortModel[0],
                  pinnedColumns: {},
                  gridDensity: 'compact',
                },
              };
              dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
              dispatch(
                globalActions.setSnackBar({
                  message: 'Columns have been reset.',
                  severity: 'success',
                }),
              );
            },
          }}
          density={density}
        />
      </>
    );
  }, [density, rows, currentColumns, defaultSortModel, currentSortModel, currentColumnVisibilityModel, isGettingLogs]);
  return (
    <Box>
      <Paper>
        <div className={classes.title}>{t('FacilityCommunicationHistory.communicationHistory')}</div>
        <Box className={classes.grid}>
          <Grid container>
            <Grid item xs={12}>
              {GridComponent}
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {isModalOpen && <EmailModal data={data} onClose={() => setModalOpen(false)} />}
      {notesModal?.open && (
        <NotesModal
          rowId={notesModal.rowId}
          log={notesModal.data}
          onClose={() => setNotesModal(prevState => ({ ...prevState, open: false }))}
          isPreview={notesModal.isPreview}
          isCandiDateProfile={false}
          open={notesModal.open}
        />
      )}
    </Box>
  );
};
