import { INotificationLogResult } from 'app/models/ActivityFeed/IActivityLogs';
import { IUpdateActivityFeedReadStatusCommand } from 'app/models/ActivityFeed/IUpdateActivityFeedReadStatus';
import { INotificationFeedResponse, NotificationSender, TaskNotificationType } from 'app/models/Tasks/Tasks';
import moment from 'moment-timezone';

export const convertToEndOfDay = (dateString: string): Date => {
  const date = new Date(dateString);
  
  // Set the time to the end of the day
  date?.setHours(23, 59, 59, 999);
  
  return date;
}

export const getTaskEntityValue = (container, t) => {
  switch (container) {
    case 1:
      return t('notification.taskEntities.placement');
    case 2:
      return t('notification.taskEntities.candidate');
    case 3:
      return t('notification.taskEntities.facility');
    case 4:
      return t('notification.taskEntities.order');
    default:
      return t('notification.taskEntities.placement');
  }
};

export const getNotificationData = (data: INotificationLogResult) => {
  let multiLine = false;
  let contentProps;
  if (data.channel === 'Alert') {
    contentProps = {
      id: data?.id,
      name: data?.message?.tos?.find(Boolean).name,
      body: data?.message?.body,
      taskId: '',
      associateRecords: data?.associatedRecords,
      attachments: data?.message?.attachmentUrls,
      useType: data?.useType,
      useSubType: data?.useSubType,
      channel: data?.channel,
      tos: data?.message?.tos,
      isNotificationUnread: data?.isNotificationUnread,
    };
    if (parseInt(contentProps?.useSubType) === TaskNotificationType?.CUENOTEREPLY) {
      contentProps?.associateRecords?.forEach(record => {
        if (record?.name === 'Description') {
          if (record?.value?.length > 30) {
            multiLine = true;
          } else {
            multiLine = false;
          }
        }
      });
    } else {
      contentProps?.associateRecords?.forEach(record => {
        if (record?.name === 'Description') {
          if (record?.value?.length > 50) {
            multiLine = true;
          } else {
            multiLine = false;
          }
        }
      });
    }
  } else {
    contentProps = {
      id: data?.id,
      recipientAddr: data.message.tos[0].email,
      status: data.message.tos[0].deliveryStatus,
      publishedOn: data.publishedOn,
      useType: data?.useType,
      useSubType: data?.useSubType,
      channel: data?.channel,
      isRead: !!data.message.readStatus && data.message.readStatus.isRead,
      hasAttachments: data.message?.attachments?.length > 0 || data.message.attachmentUrls.length > 0,
      message: {
        messageId: data.response?.messageId,
        body: data.message.body,
        ccs: data.message.ccs,
        tos: data.message.tos,
        bccs: data.message.bccs,
        subject: data.message.subject,
      },
      associateRecords: data?.associatedRecords,
      isNotificationUnread: data?.isNotificationUnread,
    };
  }

  const notificationProps: INotificationFeedResponse = { contentProps, multiLine, channel: data.channel };
  return notificationProps;
};

export const updateReadNotification = (
  logIds: string[],
  employeeId: string,
  MessageId?: string,
): IUpdateActivityFeedReadStatusCommand => {
  const payload: IUpdateActivityFeedReadStatusCommand = {};
  if (!!employeeId) {
    payload.UpdateActivitiesReadCommand = {
      //TODO: Need to confirm the userId here with all other notifications and remove userId from Notification Sender
      Sender: { ...NotificationSender, userId: '' },
      ContactId: employeeId.toString(),
      IsRead: true,
      Channel: 'Alert',
      ReadOn: moment.tz(new Date(), 'America/Los_Angeles').format(),
      NotificationLogIds: logIds,
    };
  }
  return payload;
};

export const displaySentDate = (NotificationBody, dueDateValue, activityFeed, setdueDate, sendDate, t) => {
  NotificationBody?.associateRecords?.map(records => {
    if (activityFeed ? records?.name === sendDate : records?.name === dueDateValue) {
      const formattedDate = moment.tz(convertToEndOfDay(records?.value), 'America/Los_Angeles');
      if (formattedDate.diff(moment(), 'days') <= -2) {
        const due = !activityFeed
          ? t('notification.taskNotification.due') + ' ' + formattedDate.format('L')
          : formattedDate.format('L');
        setdueDate(due);
      } else if (formattedDate.diff(moment(), 'days') === -1) {
        setdueDate(
          !activityFeed
            ? t('notification.taskNotification.due') + ' ' + t('notification.taskDetails.yesterdayDate')
            : t('notification.taskDetails.yesterdayDate'),
        );
      } else if (formattedDate.diff(moment(), 'days') === 0) {
        const due = !activityFeed
          ? t('notification.taskNotification.due') + ' ' + formattedDate.fromNow()
          : formattedDate.isBefore()
          ? formattedDate.fromNow()
          : t('notification.taskNotification.aFewSecondsAgo');
        setdueDate(due);
      } else if (formattedDate.diff(moment(), 'days') === 1) {
        setdueDate(
          !activityFeed
            ? t('notification.taskNotification.due') + ' ' + t('notification.taskNotification.tomorrow')
            : t('notification.taskNotification.tomorrow'),
        );
      } else {
        const due = !activityFeed
          ? t('notification.taskNotification.due') + ' ' + formattedDate.format('L')
          : formattedDate.format('L');
        setdueDate(due);
      }
    }
  });
};

export const FormatDate = (date, format?: any) => {
  return moment.tz(date, 'America/Los_Angeles').format(format);
};

export const FormatSystemDate = (date, format?: any) => {
  return moment(date).format(format);
};

export const CONSTANTS_NOTIFICATIONS = {
  PREFERENCES: {
    IMAGEID: 'preference-notification-image-id',
    USETYPE: ['Order Match', 'order match'],
  },
  TASK: {
    IMAGEID: 'task-notification-image-id',
    USETYPE: 'Task',
  },
  ALERT: {
    IMAGEID: 'alert-notification-image-id',
    USETYPE: 'Alert',
  },
  CUENOTE:{
    USETYPE:'Cue Note'
  },
  ACTION_PANEL: {
    MARK_READ: 'MARK READ',
    OPEN_TASK: 'OPEN TASK',
    GO_TO: 'GO TO',
    CREATE_PID: 'CREATE PID',
    VIEW_CUE: 'VIEW CUE',
    NO_CUE: 'NO CUE',
    VIEW_STATUS: 'VIEW STATUS',
  },
};


