/* eslint-disable react-hooks/exhaustive-deps */
import { DrawerFitted } from '@AMIEWEB/Common';
import { NavigationProfileCycle } from '@AMIEWEB/Common/NavigationProfileCycle';
import { SearchDrawerContent } from '@AMIEWEB/GlobalSearch/SearchResultsPage';
import { navigationProfilesAction } from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.redux';
import {
  selectNavigationProfilesFlag,
  selectNavigationProfilesItems,
  selectNavigationRowId,
} from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.selectors';
import { setEntityStickChoosen } from 'app/components/Tasks/TaskSidebar/Transformers';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import { selectTaskStickChosen } from 'app/components/Tasks/store/Tasks.selectors';
import { DetailsPage } from 'app/layout/pages/DetailsPage';
import { FacilityDetailsHeader, FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { ITaskStickChosen, TaskEntity } from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { QuickGlance } from '../../../models/Facility/FacilityDetails';
import { FacilityBody } from './FacilityBody/FacilityBody';
import { FacilityHeader } from './FacilityHeader/FacilityHeader';
import { FacilityHeaderWrapper } from './FacilityHeaderWrapper';
import { FacilityQuickGlance } from './FacilityQuickGlance/FacilityQuickGlance';
import {
  clientContactReducer,
  clientContactSliceKey,
} from './FacilityTabs/CreateClientContact/store/ClientContact.redux';
import { clientContactSaga } from './FacilityTabs/CreateClientContact/store/ClientContact.saga';
import { getAddress, getCreditCheck, getValue } from './helper';
import { selectFacilityDetail } from '../FacilityStore/Facility.selector';
import {
  FacilityCommunicationHistorySliceKey,
  FacilityCommunicationHistoryReducer,
} from './FacilityTabs/CommunicationHistory/store/FacilityCommunicationHistory.redux';
import { FacilityCommunicationHistorySaga } from './FacilityTabs/CommunicationHistory/store/FacilityCommunicationHistory.saga';

interface IFacilityDetailsWrapper {
  navigationData: any;
  isCreditHold: boolean;
  isCreditPending: boolean;
  isMaxBookingLimit: boolean;
  isOverrideEligible: boolean;
  handleUnitCreation: (value: boolean) => void;
  handleOrderCreation: (confirmation: boolean, activateStep?: number) => void;
  determineCreditHoldOverride: () => void;
}

export const FacilityDetailsWrapper = (props: IFacilityDetailsWrapper) => {
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  useInjectReducer({ key: clientContactSliceKey, reducer: clientContactReducer });
  useInjectSaga({ key: clientContactSliceKey, saga: clientContactSaga });
  useInjectReducer({ key: FacilityCommunicationHistorySliceKey, reducer: FacilityCommunicationHistoryReducer });
  useInjectSaga({ key: FacilityCommunicationHistorySliceKey, saga: FacilityCommunicationHistorySaga });

  const {
    navigationData,
    isCreditHold,
    isCreditPending,
    isMaxBookingLimit,
    isOverrideEligible,
    handleOrderCreation,
    handleUnitCreation,
    determineCreditHoldOverride,
  } = props;
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();
  const navigationProfiles = useSelector(selectNavigationProfilesItems);
  const taskStickChosen = useSelector(selectTaskStickChosen);
  const { drawerData } = useSelector(selectSearchResults);
  const navigationFlag = useSelector(selectNavigationProfilesFlag);
  const facilityDetailsResponse: FacilityResponse | null = useSelector(selectFacilityDetail);
  const navigationRowId = useSelector(selectNavigationRowId);
  const [upDisabled, setUpDisabled] = useState<boolean>(false);
  const [downDisabled, setDownDisabled] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [disableOrderBtn, setDisableOrderBtn] = useState(false);
  const [stickChoosen, setStickChoosen] = useState<ITaskStickChosen>({ entity: TaskEntity.FACILITIES, stick: null });
  const facilityHeader: FacilityDetailsHeader = {
    facilityId: facilityDetailsResponse?.facilityId,
    facilityName: facilityDetailsResponse?.facilityName,
    healthSystemName: facilityDetailsResponse?.healthSystemName,
    facilityAddress: getAddress(facilityDetailsResponse?.location),
    facilityStatus: facilityDetailsResponse?.facilityStatus,
  };

  useEffect(() => {
    let currVal;
    let navigationIndex = navigationProfiles?.findIndex(s => s.id === navigationRowId);
    currVal = navigationIndex;
    setDownDisabled(navigationIndex === navigationProfiles?.length - 1);
    setUpDisabled(navigationIndex === 0);
    setCurrentIndex(currVal);
  }, [params.id, navigationRowId]);

  const [quickGlanceDetails, setQuickGlanceDetails] = useState<QuickGlance>({
    creditCheck: getValue(isCreditHold || isCreditPending),
    bookingLimit: facilityDetailsResponse?.bookingLimit ? facilityDetailsResponse?.bookingLimit.toString() : '0',
    currentOA: facilityDetailsResponse?.currentOA ? facilityDetailsResponse?.currentOA.toString() : '0',
    historicalOA: facilityDetailsResponse?.historicalOA ? facilityDetailsResponse?.historicalOA.toString() : '0',
    locationOA: facilityDetailsResponse?.locationOA ? facilityDetailsResponse?.locationOA.toString() : '0',
    openPositions: facilityDetailsResponse?.positionsAvailable
      ? facilityDetailsResponse?.positionsAvailable.toString()
      : '0',
    filesOut: facilityDetailsResponse?.filesOut ? facilityDetailsResponse?.filesOut.toString() : '0',
  });
  const handleQuickGlanceValueChange = (value: number) => {
    //if (activateView !== value) setActivateView(value);
  };

  useEffect(() => {
    setQuickGlanceDetails({
      creditCheck: getCreditCheck(facilityDetailsResponse?.creditStatus),
      bookingLimit: facilityDetailsResponse?.bookingLimit ? facilityDetailsResponse?.bookingLimit.toString() : '0',
      currentOA: facilityDetailsResponse?.currentOA ? facilityDetailsResponse?.currentOA.toString() : '0',
      historicalOA: facilityDetailsResponse?.historicalOA ? facilityDetailsResponse?.historicalOA.toString() : '0',
      locationOA: facilityDetailsResponse?.locationOA ? facilityDetailsResponse?.locationOA.toString() : '0',
      openPositions: facilityDetailsResponse?.positionsAvailable
        ? facilityDetailsResponse?.positionsAvailable.toString()
        : '0',
      filesOut: facilityDetailsResponse?.filesOut ? facilityDetailsResponse?.filesOut.toString() : '0',
    });
  }, [facilityDetailsResponse]);

  const truncateWithElips = (str: string, limit: number) => {
    return (str.trim().length || 0) > limit ? `${str.trim().slice(0, limit)}...` : `${str.trim() || ''}`;
  };

  const credHoldMessage = '{0} has a credit hold. Order creation is restricted at this time.'.replace(
    `{${0}}`,
    facilityDetailsResponse?.facilityName ? truncateWithElips(facilityDetailsResponse?.facilityName, 15) : 'Facility',
  );
  const bookingLimitMessage =
    '{0} has reached the max booking limit. Please reach out to your leader for approval.'.replace(
      `{${0}}`,
      facilityDetailsResponse?.facilityName ? truncateWithElips(facilityDetailsResponse?.facilityName, 15) : 'Facility',
    );
  const ineligibleStatusMessage = 'This facility is in {0} status. Order creation is restricted at this time.'.replace(
    `{${0}}`,
    facilityDetailsResponse?.facilityStatus
      ? truncateWithElips(facilityDetailsResponse?.facilityStatus, 15)
      : 'an ineligible',
  );

  useEffect(() => {
    setStickChoosen(taskStickChosen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStickChosen]);

  useEffect(() => {
    dispatch(taskDetailsActions.setCurrentContainer(TaskEntity.FACILITIES));
    setEntityStickChoosen(TaskEntity.FACILITIES, dispatch);
    determineCreditHoldOverride();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const facilityStatus = facilityHeader?.facilityStatus?.toUpperCase();
    if (facilityStatus === 'DELETE' || facilityStatus === 'CLOSED' || facilityStatus === 'INACTIVE') {
      setDisableOrderBtn(true);
    } else {
      setDisableOrderBtn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityHeader?.facilityStatus]);

  const handleClickUp = () => {
    const nextIdx = navigationProfiles[currentIndex - 1];
    const nextFacilityId = nextIdx?.facilityId;
    dispatch(navigationProfilesAction.setCurrentRowId({ rowId: navigationProfiles[currentIndex - 1]?.id }));
    history.push({
      pathname: `/facility/${nextFacilityId}`,
      state: 'navigationCycle',
    });
   
  };

  const handleClickDown = () => {
    let nextIdx = navigationProfiles[currentIndex + 1];
    const nextFacilityId = nextIdx?.facilityId;
    dispatch(navigationProfilesAction.setCurrentRowId({ rowId: navigationProfiles[currentIndex + 1]?.id }));
    history.push({
      pathname: `/facility/${nextFacilityId}`,
      state: 'navigationCycle',
    });
   
  };

  return (
    <div>
      <DetailsPage
        title={`Facility Details`}
        head={facilityHeader && <FacilityHeader />}
        statTiles={
          <FacilityQuickGlance quickGlanceDetails={quickGlanceDetails} onClick={handleQuickGlanceValueChange} />
        }
        titleActions={
          <FacilityHeaderWrapper
            stickIconArray={[4]}
            taskStickChosen={stickChoosen}
            isOverrideEligible={isOverrideEligible}
            handleUnitCreation={handleUnitCreation}
            credHoldMessage={credHoldMessage}
            isMaxBookingLimit={isMaxBookingLimit}
            bookingLimitMessage={bookingLimitMessage}
            handleOrderCreation={handleOrderCreation}
            setTooltipIsOpen={setTooltipIsOpen}
            tooltipIsOpen={tooltipIsOpen}
            disableOrderBtn={disableOrderBtn}
            ineligibleStatusMessage={ineligibleStatusMessage}
          />
        }
        navigationActions={
          navigationProfiles?.length > 0 && (navigationFlag || location?.state === 'navigationCycle') ? (
            <NavigationProfileCycle
              handleClickUp={handleClickUp}
              handleClickDown={handleClickDown}
              disabledUp={upDisabled}
              disabledDown={downDisabled}
            />
          ) : null
        }
        children={
          <FacilityBody
            navigationData={navigationData}
            facilityHeader={facilityHeader}
            isCreditHold={isCreditHold}
            isMaxBookingLimit={isMaxBookingLimit}
          />
        }
        currentContainer={TaskEntity.FACILITIES}
        taskStickChosen={stickChoosen}
      />

      {drawerData?.data?.selectedorder?.row?.candidateId && (
        <DrawerFitted
          onDrawerClose={() => {
            dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
          }}
          width={450}
          top={0}
          open={drawerData?.open ?? false}
        >
          {drawerData?.open && drawerData?.data ? (
            <SearchDrawerContent category={'placement'} data={drawerData?.data} />
          ) : null}
        </DrawerFitted>
      )}
    </div>
  );
};
